import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  @font-face {
    font-family: Avenir Book;
    src: url("/fonts/Avenir-Book.otf");
    font-display: auto;
  }

  @font-face {
    font-family: Avenir Book;
    src: url("/fonts/Avenir-Book.otf");
    font-weight: bold;
  }

  --font-style-default: Avenir Book;

  p, div, span {
    font-family: var(--font-style-default);
    font-size: 16px;
  }
  p{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

body {
  padding: 0px;
  margin: 0px;
}

.line__height--1{
  line-height: 1;
}
.font__weight--700 {
  font-weight: 700;
  color: #939597;
}
.c-pointer{
  cursor: pointer;
}
.page-item.active .page-link {
  color: #fff !important;
  background-color: #6e0072 !important;
  border-color: #6e0072 !important;
}
.page-link {
  color: #6e0072 !important;
}
.list__implants{
  overflow-y: scroll;
  height: 34vh;
}

.bgColor-primary{
  background-color: #6e0072 !important;
  color: white;
}
.custom-select {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1em;
}
.custom-input {
  border-radius: 36px;
  border: 0;
  background: #f3f3f4;
  height: 45px;
  padding-left: 15px;
  outline: none;
  width: 100%;
  font-family: 'Avenir'
}
  .custom-button {
    height: 45px;
    padding: 0 25px; 
    border: 0;
    border-radius: 50px;
    text-transform: uppercase;
  }
  .react-datepicker-popper{
    z-index: 999999;
  }
  .custom-file-input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      pointer-events: none;
  }

  .custom-file-label {
      display: inline-block;
      background-color: #6e0072;
      color: #fff;
      font-size: 14px;
      padding: 8px 16px;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;
      transition: background-color 0.3s ease;
      width: auto;
  }

  .custom-file-label:hover {
      background-color: #8a0091;
  }
  
  .custom-file-container {
      position: relative;
      display: inline-block;
      width: auto;
  }

  .custom-file-container input[type="file"] {
      pointer-events: all;
  }
  
  .date-input-container {
      gap: 8px;
  }
  
  .date-label {
      font-size: 14px;
      color: #555;
      font-weight: bold;
      margin-bottom: 4px;
  }

  .custom-date-input {
      width: 100%;
      padding: 10px 12px;
      font-size: 16px;
      color: #333;
      background-color: #f9f9f9;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
      transition: border-color 0.3s ease, box-shadow 0.3s ease;
      outline: none;
      appearance: none;
  }

  .custom-date-input:focus {
      border-color: #6e0072;
      box-shadow: 0 0 0 3px rgba(110, 0, 114, 0.2);
      background-color: #fff;
  }
  
  .custom-date-input:hover {
      border-color: #8a0091;
  }
`;


