import React from "react";
import Layout from "../../../layouts/Layout";
import RebateProgramCatalog from "../../../components/maintenance/RebateProgramCatalog";

export default function RebateProgram() {
    return (
        <Layout topComponent={<>
            <h2 className="titleCatalogs">Influencer Offer</h2>
        </>}>
            <RebateProgramCatalog />
        </Layout>
    );
}