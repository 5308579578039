import React from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    backgroundColor: "white",
    boxShadow: 24,
    p: 4,
    outline: "none",
    maxHeight: "80%",
    overflowY: "scroll",
};

export const ModalInfluencerComponent = ({
                                   open,
                                   close,
                                   title,
                                   content,
                                   actions,
                                   errorMessage,
                                   media
                               }) => {
    return (
        <Modal open={open} onClose={close}>
            <>
                <Box sx={style}>
                    {errorMessage && (
                        <Box
                            sx={{
                                position: "relative",
                                top: "-30px",
                            }}
                        >
                            <Alert
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    font: "normal normal 900 20px/20px Avenir",
                                }}
                                icon={false}
                                severity={"error"}
                            >
                                {errorMessage}
                            </Alert>
                        </Box>
                    )}
                    <Box mb={2} display="flex" justifyContent="space-between">
                        <Typography variant="h5">{title}</Typography>
                        <IconButton aria-label="close" onClick={close}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box display="flex" gap={2}>
                        {media && (
                            <Box
                                sx={{
                                    flex: "1",
                                    maxWidth: "40%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "none",
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                    height: "auto",
                                }}
                            >
                                {media.type === "img" && (
                                    <img
                                        src={media.src}
                                        alt="Preview"
                                        style={{
                                            width: "100%",
                                            height: "auto",
                                            objectFit: "cover",
                                        }}
                                    />
                                )}
                                {media.type === "vid" && (
                                    <video
                                        controls
                                        autoPlay
                                        muted
                                        style={{
                                            width: "100%",
                                            height: "auto",
                                        }}
                                    >
                                        <source src={media.src} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                )}
                            </Box>
                        )}

                        <Box sx={{ flex: "2",
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            gap: 1}}>{content}</Box>
                    </Box>
                </Box>
            </>
        </Modal>
    );
};
