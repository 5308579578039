import React, {createContext, useState, useContext, useEffect} from "react";

import {AzureAuthContext} from "./AzureAuthContext";
import API, {ROLES, roles} from "../api";

export const APIContext = createContext({});

export const APIContextProvider = ({children}) => {
    const {userProfile} = useContext(AzureAuthContext);

    const api = new API({
        headers: {
            Authorization:
                userProfile.userPrincipalName &&
                `Basic ${Buffer.from(userProfile.userPrincipalName).toString(
                    "base64"
                )}`,
        },
    });

    const warrantiesApi = new API({
        headers: {
            Authorization:
                userProfile.userPrincipalName &&
                `Basic ${Buffer.from(userProfile.userPrincipalName + ':' + "").toString(
                    "base64")}`,
        },
    });

    const [countries, setCountries] = useState([]);
    const [countriesArray, setCountriesArray] = useState([]);

    const patientSearch = api.patientSearch.bind(api);
    const getPatientErrors = api.getPatientErrors.bind(api);
    const getImplantDetail = api.getImplantDetail.bind(api);
    const getImplantTransactions = api.getImplantTransactions.bind(api);

    const getCountries = api.getCountries.bind(api);
    const getVersion = api.getVersion.bind(api);
    const getFeatures = api.getFeatures.bind(api);

    // const getIncisionKinds = api.getIncisionKinds.bind(api);
    // const getSurgeryKinds = api.getSurgeryKinds.bind(api);
    // const getBreastPlacements = api.getBreastPlacements.bind(api);

    const postValidateDevice = api.postValidateDevice.bind(api);
    const postCompleteTemporaryExplant = api.postCompleteTemporaryExplant.bind(api);
    const postPatient = api.postPatient.bind(api);
    const postPatientSendEmail = api.postPatientSendEmail.bind(api);
    const postPatientGeneratePassword = api.postPatientGeneratePassword.bind(api);
    const postImplant = api.postImplant.bind(api);
    const postSurgery = api.postSurgery.bind(api);
    const postWarrantyExtend = api.postWarrantyExtend.bind(api);
    const surgeonSearch = api.surgeonSearch.bind(api);
    const postUserType = api.postUserType.bind(api);

    const postAddImplant = warrantiesApi.postAddImplant.bind(warrantiesApi);

    const getUser = api.getUser.bind(api);
    const getUsers = api.getUsers.bind(api);
    const getUsersActivityLog = api.getUsersActivityLog.bind(api);
    const postUser = api.postUser.bind(api);
    const updateUser = api.updateUser.bind(api);
    const deleteUser = api.deleteUser.bind(api);

    const getDistributorReport = api.getDistributorReport.bind(api);
    const getDistributors = api.getDistributors.bind(api);
    const getImplantsReport = api.getImplantsReport.bind(api);
    const getCustomerCareReport = api.getCustomerCareReport.bind(api);
    const getDeviceTrackingReport = api.getDeviceTrackingReport.bind(api);
    const getDeviceTrackingTempReport = api.getDeviceTrackingTempReport.bind(api);
    const getDownloadPMS = api.getDownloadPMS.bind(api);
    const getBIAnnualReport = api.getBIAnnualReport.bind(api);

    const getCatalogByGroup = api.getCatalogByGroup.bind(api);
    const updateCatalog = api.updateCatalog.bind(api);
    const postSendEmails = api.postSendEmails.bind(api);


    const getAllDistributors = api.getAllDistributors.bind(api);
    const updateDistributor = api.updateDistributor.bind(api);
    const createDistributor = api.createDistributor.bind(api);
    const deleteDistributor = api.deleteDistributor.bind(api);

    const getAllUsersApplication = api.getAllUsersApplication.bind(api);
    const createUsersApplication = api.createUsersApplication.bind(api);

    const getAllInfluencers = warrantiesApi.getAllInfluencers.bind(warrantiesApi);
    const updateInfluencer = warrantiesApi.updateInfluencer.bind(warrantiesApi);
    const createInfluencer = warrantiesApi.createInfluencer.bind(warrantiesApi);
    const deleteInfluencer = warrantiesApi.deleteInfluencer.bind(warrantiesApi);

    useEffect(
        () =>
            getCountries().then(({Countries: countries}) => {
                setCountries(Object.fromEntries(countries.map((c) => [c.id, c.name])))
                setCountriesArray(countries)
            }),
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /*
    useEffect(() => getIncisionKinds().then(console.log), []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => getSurgeryKinds().then(console.log), []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => getBreastPlacements().then(console.log), []); // eslint-disable-line react-hooks/exhaustive-deps
    */

    const countryById = (id) => countries[id];

    return (
        <APIContext.Provider
            value={{
                roles,
                ROLES,
                getUser,
                postUser,
                updateUser,
                deleteUser,
                getUsers,
                getUsersActivityLog,

                patientSearch,
                getPatientErrors,
                getImplantDetail,
                getImplantTransactions,
                getCountries,
                postValidateDevice,
                postCompleteTemporaryExplant,
                postPatient,
                postPatientSendEmail,
                postPatientGeneratePassword,
                postImplant,
                postSurgery,
                postWarrantyExtend,
                surgeonSearch,
                postUserType,
                postAddImplant,

                getDistributorReport,
                getDistributors,

                getCustomerCareReport,
                getImplantsReport,
                getDeviceTrackingTempReport,
                getDeviceTrackingReport,
                getDownloadPMS,
                getBIAnnualReport,

                countries,
                countriesArray,
                countryById,
                getCatalogByGroup,
                updateCatalog,
                getVersion,
                getFeatures,
                postSendEmails,

                getAllDistributors,
                updateDistributor,
                createDistributor,
                deleteDistributor,

                getAllUsersApplication,
                createUsersApplication,

                getAllInfluencers,
                updateInfluencer,
                createInfluencer,
                deleteInfluencer,
            }}
        >
            {children}
        </APIContext.Provider>
    );
};
