import React, { useContext, useState } from "react";
import { Button } from "../../form";
import { TableJavaComponent } from "../../base/TableJavaComponent";
import { APIContext } from "../../../contexts/APIContext";
import { ModalInfluencerComponent } from "../../base/ModalInfluencerComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormComponent } from "../../dynamic/form";
import { formRebateProgramCatalog } from "../../../forms";
import { useTranslation } from "react-i18next";
import AWS from "aws-sdk";
import SearchBoxUsers from "../../base/SearchBoxUsers";

export default function RebateProgramCatalog() {
    const { t } = useTranslation();
    const { getAllInfluencers, updateInfluencer, createInfluencer, deleteInfluencer } = useContext(APIContext);
    const [influencers, setInfluencers] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [file, setFile] = useState(null);
    const [typeFile, setTypeFile] = useState(null);
    const landingURL = process.env.REACT_APP_LANDING_INFLUENCER

    const convertToISO8601 = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString();
    };

    const isImageOrVideo = (fileName) => {
        const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];
        const videoExtensions = ["mp4", "avi", "mov", "mkv", "webm", "flv", "wmv"];

        const fileExtension = fileName.split(".").pop().toLowerCase();

        if (imageExtensions.includes(fileExtension)) {
            return "img";
        } else if (videoExtensions.includes(fileExtension)) {
            return "vid";
        } else {
            return null;
        }
    };

    const convertToDateInputFormat = (isoString) => {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const uploadFile = async (file) => {
        const S3_BUCKET = process.env.REACT_APP_AWS_NAME;
        const REGION = process.env.REACT_APP_AWS_REGION;

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        });

        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        var nameFile =  `influencers/${Date.now()}_${file.name}`;

        const params = {
            Bucket: S3_BUCKET,
            Key: nameFile,
            Body: file,
        };

        try {
            const data = await s3.upload(params).promise();
            return process.env.REACT_APP_IMAGE_INFLUENCER + nameFile;
        } catch (error) {
            console.error("Error uploading file to S3:", error);
            throw error;
        }
    };

    const handleSubmitFilter = (criteria) => {
        const queryParams = Object.entries(criteria)
            .filter(([key, value]) => value)
            .map(([key, value]) => `${key}=${value}`)
            .join("&");

        getData(0, queryParams);
    };

    const getData = (page, queryParams) => {
        setLoading(true);
        queryParams = queryParams != null ? `&${queryParams}` : '';
        getAllInfluencers(page, queryParams)
          .then((data) => {
              const processedData = data.influencers.content.map((influencer) => ({
                  ...influencer,
                  startDate: convertToDateInputFormat(influencer.startDate),
                  endDate: convertToDateInputFormat(influencer.endDate),
              }));

              data.influencers.content = processedData;

              setInfluencers(data.influencers);
          })
          .finally(() => setLoading(false));
    };

    const newItem = () => {
        setTypeFile("");
        setFile("");
        setOpenModal(true);
    };

    const editItem = (row) => {
        setOpenModal(true);
        formik.setValues({
            id: row.id,
            hash: row.hash,
            codePrefix: row.codePrefix,
            firstName: row.firstName,
            lastName: row.lastName,
            startDate: row.startDate,
            endDate: row.endDate,
            instagram: row.instagram,
            termsAndConditionsUrl: row.termsAndConditionsUrl,
            note: row.note,
            active: row.active,
        });

        if (row.documentUri) {
            setTypeFile(row.documentType);
            setFile(row.documentUri);
        }
    };

    const deleteItem = async (id) => {
        let payload = {
            id: id
        }
        await deleteInfluencer(payload.id).then((data) => {
            setInfluencers([]);
            getData(0);
            closeModal();
        }).catch((e) => {
            setErrorMessage("An error occurred while deleting the influencer");

            setTimeout(()=> {
                setErrorMessage(null);
            }, 4000)
        });
    }

    const closeModal = () => {
        setOpenModal(false);
        formik.resetForm();
    };

    const handlePreSubmit = async (values) => {

        if (document.getElementsByName("documentUri")[0].files.length > 0 ) {
            try {
                const uploadedFileName = await uploadFile(document.getElementsByName("documentUri")[0].files[0]);
                values.documentUri = uploadedFileName;
                values.documentType  = isImageOrVideo(uploadedFileName);
            } catch (error) {
                values.documentUri = null;
                values.documentType  =  null;
                return;
            }
        }
        else {
            values.documentUri = file ? file : null;
            values.documentType  = typeFile ? typeFile : null;
        }
    };

    const Schema = Yup.object({
        codePrefix: Yup.string().required(),
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        startDate: Yup.date().required(),
        endDate: Yup.date().required(),
    });

    const formik = useFormik({
        initialValues: {
            id: "",
            codePrefix: "",
            firstName: "",
            lastName: "",
            startDate: "",
            endDate: "",
            documentUri: null,
            documentType: null,
            instagram: "",
            termsAndConditionsUrl: "",
            note: "",
            active: 1,
            hash: ""
        },
        validationSchema: Schema,
        onSubmit: async (values) => {
            const payload = {
                ...values,
            };

            payload.codePrefix = payload.codePrefix ? values.codePrefix.trim() : payload.codePrefix;
            payload.firstName = payload.firstName ? values.firstName.trim() : payload.firstName;
            payload.lastName = payload.lastName ? values.lastName.trim() : payload.lastName;
            payload.instagram = payload.instagram ? values.instagram.trim() : payload.instagram;
            payload.note = payload.note ? values.note.trim() : payload.note;
            payload.termsAndConditionsUrl = payload.termsAndConditionsUrl ? values.termsAndConditionsUrl.trim() : payload.termsAndConditionsUrl;

            await handlePreSubmit(payload);

            const apiCall = values.id ? updateInfluencer : createInfluencer;
            payload.startDate = convertToISO8601(payload.startDate);
            payload.endDate = convertToISO8601(payload.endDate);

            apiCall(payload)
                .then(() => {
                    setInfluencers([]);
                    getData(0);
                    closeModal();
                })
                .catch(console.error);
        },
    });

    const actions = {
        close: closeModal,
        delete: deleteItem
    }

    return (
        <>
            <Button onClick={() => newItem()} primary>{t("general.new")}</Button>
            <SearchBoxUsers onSearch={handleSubmitFilter} availableCriterias={[
                {key: "codePrefix", label: "Search By Influencer Code"},
                {key: "firstName", label: "Search By Name"},
                {key: "lastName", label: "Search By Last Name"},
            ]} />
            {influencers && (
                <TableJavaComponent
                    rows={influencers}
                    columns={[
                        { label: "Landing URL", link: landingURL , keyValue: "hash", name: "Site"},
                        { label: "Influencer Code", keyValue: "codePrefix" },
                        { label: "Name", keyValue: "firstName" },
                        { label: "Patient Last Name", keyValue: "lastName" },
                        { label: "Start Date", keyValue: "startDate" },
                        { label: "End Date", keyValue: "endDate" },
                        { label: "Instagram", keyValue: "instagram" },
                        { label: "Terms and Conditions", keyValue: "termsAndConditionsUrl" },
                        { label: "Notes", keyValue: "note" },
                        { label: "Active", keyValue: "active" },
                    ]}
                    editRow={editItem}
                    fetchData={getData}
                    autoLoad
                    isLoading={loading}
                />
            )}
            {openModal && (
                <ModalInfluencerComponent
                    open={openModal}
                    close={closeModal}
                    errorMessage={errorMessage}
                    title={ formik.values.id === "" ? t("settings.influencer.new") : t("settings.influencer.edit") }
                    content={
                        <FormComponent formFields={[ ...formRebateProgramCatalog(t, formik),]}
                            formik={formik}
                                       action={actions}
                        />
                    }
                    media={ file ? { type: typeFile, src: file} : null }
                />
            )}
        </>
    );
}